import { hydrate } from "@/utils/form";

export default class Credit {
    id = null;
    name = null;
    type = 'place';
    enabled = true;
    description = null;
    validityDurationCount = null;
    validityPeriodicityType = null;
    club = JSON.parse(localStorage.getItem('selectedClub'));

    constructor(object, options) {
        if ("undefined" !== typeof options) {
            if(options.serialize) {
                this.serialize(object);
            }
            if(options.deserialize) {
                this.deserialize(object);
            }
        }
    }

    serialize (object) {
        hydrate(this, object);
    }
    deserialize (object) {
        this.castToInt(object);
        hydrate(this, object);
    }
    castToInt(object) {
        object.validityDurationCount = parseInt(object.validityDurationCount);
    }

}
