<template>
  <div>
    <d-body-top-bar :title="$t('views.credits.credit-create-update.add-credit')">
      <div slot="tree">
        <label class="inactive-tree">
          {{ $t('views.subscriptions.subscriptions-list') }} /
          <label class="inactive-tree link-area pointer" @click="$router.push({name: 'credit_list'})">
            {{ $t('views.credits.credit-create-update.credits') }} /
          </label>
        </label>
        <label class="bg-doinsport-primary ml-1"> {{ $t('views.credits.credit-create-update.add-credit') }}</label>
      </div>
      <div slot="icons">
      </div>
    </d-body-top-bar>
    <div class="container-fluid credits-card-container">
      <b-card class="credits-card">
        <validation-observer
          ref="creditObserver"
          slim
        >
          <b-row>
            <!-- <b-col :class="widthCheck (1100) ? 'col-divider-5': ''" :cols="widthCheck (1100) ? '12' : 12"> -->
            <b-col cols="12">
              <credit-informations
                :credit="credit"
                class="mt-3"
              />
            </b-col>
            <!-- todo for v3.0
            <div v-if="widthCheck (1100)" class="split-layout__divider">
              <div class="split-layout__rule"></div>
              <div class="split-layout__rule"></div>
            </div>
            <b-col :cols="widthCheck (1100) ? 6 : 12">
              <credit-access/>
            </b-col> -->
          </b-row>
        </validation-observer>
        <b-row>
          <b-col align="right">
            <d-button
              text="general.actions.save"
              class="d-btn-sm font-text-title btn d-btn-danger font-text-title"
              @on:button-click="onSave()"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>
<script>
import {getCredit, postCredit, putCredit} from "@api/doinsport/services/credits/credits.api";
import Credit from "@/classes/doinsport/Credit";
import {SUCCESS} from "@plugins/flash";

export default {
  data: () => ({
    credit: new Credit(),
  }),
  components: {
    CreditInformations: () => import('./credit-informations/Index'),
    CreditAccess: () => import('./credit-access/AccessControl')
  },
  methods: {
    widthCheck(check) {
      return this.$store.getters['layout/getInnerWidth'] > check;
    },
    onSave() {
      try {
        this.$refs.creditObserver.validate().then((valid) => {
          if (valid) {
            if (this.credit.id === null) {
              const deserializedCredit = new Credit(this.credit, {deserialize: true});

              postCredit(deserializedCredit).finally(() => {
                this.$router.push({name: 'credit_list'});
              });
            } else {
              const deserializedCredit = new Credit(this.credit, {deserialize: true});

              putCredit(deserializedCredit)
                .finally(() => {
                  this.$router.push({name: 'credit_list'});
                  this.$flash(null, this.$t('general.actions.success-update'), 5000, SUCCESS);
                })
              ;
            }
          }
        })
      } catch (e) {
      }
    }
  },
  created() {
    if (this.$route.params.id) {
      getCredit(this.$route.params.id)
        .then((response) => {
          this.credit = new Credit(response.data, {serialize: true});
        })
      ;
    }
  }
}
</script>

<style scoped>
.credits-card .card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding-top: .5rem;
  padding-bottom: 1.0rem;
  padding-left: 1.0rem;
  padding-right: 1.0rem;
}

@media only screen and (max-width: 1151px) {
  .credits-card-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.credits-card-container .card {
  box-shadow: 0px 10px 16px #E2E1E6;
  border-radius: 8px;
}

</style>
